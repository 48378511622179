import * as React from 'react';
import {
  Stack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  StackDivider,
  Text,
  Link,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Progress,
  Divider,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { api } from '../api';
import { useSearchParams } from 'react-router-dom';

const colorSchemeMap = {
  'true-positive': 'red',
  'false-positive': 'gray',
  error: 'yellow',
};

function ResultCard({ searchResult }) {
  return (
    <Card mt={4} mb={4}>
      <CardHeader>
        <Heading size="sm">
          Search query:{' '}
          <Link
            href={`https://www.google.com/search?q=${searchResult.query}`}
            isExternal
            target="_blank"
            color={'#2066e6'}
          >
            {searchResult.query}
          </Link>
        </Heading>
        {/* <Text>
          Query: <Text as="i">{searchResult.query}</Text>
        </Text> */}
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          {searchResult.results?.map((result) => (
            <Box>
              <Heading size="xs" textTransform="uppercase">
                <Badge colorScheme={colorSchemeMap[result.result]}>{result.result}</Badge>
              </Heading>

              <Link href={result.link} isExternal target="_blank">
                {result.link}
              </Link>
              <Text pt="2" fontSize="sm">
                {result.summary}
              </Text>
            </Box>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
}

function MediaCheckResult({ data }) {
  if (!data) return null;

  return (
    <Box>
      {/* <Heading size="lg">Media check results</Heading> */}
      <StatGroup>
        <Stat>
          <StatLabel>Status</StatLabel>
          <StatNumber>{data.status}</StatNumber>
          {data.status !== 'completed' && <StatHelpText>Try to refresh in 20 seconds.</StatHelpText>}
        </Stat>
        <Stat>
          <StatLabel>Search</StatLabel>
          <StatNumber>{data.params.searchQuery}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Type</StatLabel>
          <StatNumber>{data.params.type}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Page limit</StatLabel>
          <StatNumber>{data.params.pageLimit}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Model</StatLabel>
          <StatNumber>{data.params.model}</StatNumber>
        </Stat>
      </StatGroup>

      <Divider mt={4} mb={6} />

      {data.results?.map((searchResult) => (
        <ResultCard searchResult={searchResult} />
      ))}
    </Box>
  );
}

export function ViewMediaCheckForm() {
  const [mediaCheckId, setMediaCheckId] = React.useState('');
  let [searchParams, setSearchParams] = useSearchParams();
  const initialId = searchParams.get('id');

  const { data, error, isPending, mutate } = useMutation({
    mutationFn: async (id) => {
      const { data } = await api.fetchMediaCheck(id);
      return data;
    },
  });

  React.useEffect(() => {
    if (initialId) {
      setMediaCheckId(initialId);
      mutate(initialId);
    }
  }, [initialId, mutate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('mediaCheckId');
    mutate(id);
  };

  return (
    <Stack direction={'column'} width={'100%'}>
      <Box w={500}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} direction="row">
            <FormControl>
              <FormLabel htmlFor="mediaCheckId">Media check ID</FormLabel>
              <Input
                id="mediaCheckId"
                name="mediaCheckId"
                value={mediaCheckId}
                onChange={(e) => setMediaCheckId(e.target.value)}
              />
            </FormControl>
            <Button mt={8} type="submit" size="md" colorScheme="blue" isLoading={isPending} isDisabled={isPending}>
              View
            </Button>
          </Stack>
        </form>
      </Box>
      <Box mt={4}>
        {isPending ? (
          <Progress size="xs" isIndeterminate />
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          <MediaCheckResult data={data} />
        )}
      </Box>
    </Stack>
  );
}
