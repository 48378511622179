import * as React from 'react';
import {
  Radio,
  RadioGroup,
  Stack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Card,
  CardBody,
  Checkbox,
  Heading,
  StackDivider,
  Text,
  Link,
  List,
  ListItem,
  Progress,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { api } from '../api';

function ArrayResult({ arrayData = [], label }) {
  return (
    <Box>
      <Heading size="xs" textTransform="uppercase">
        {label}
      </Heading>

      {arrayData.length ? (
        <List>
          {arrayData.map((item) => (
            <ListItem>{item}</ListItem>
          ))}
        </List>
      ) : (
        <Text pt="2" fontSize="sm">
          -
        </Text>
      )}
    </Box>
  );
}

function ResultCard({ data }) {
  if (!data) return null;

  return (
    <Card w={'100%'}>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <ArrayResult arrayData={data.codeProposed} label="Proposed codes" />
          <ArrayResult arrayData={data.contactAddress} label="Address" />
          <ArrayResult arrayData={data.contactEmail} label="Contact email" />
          <ArrayResult arrayData={data.contactPhone} label="Contact phone" />
          <ArrayResult
            arrayData={data.businessCountries}
            label="Prohibited countries"
          />
          <ArrayResult arrayData={data.partners} label="Partners" />
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Controlled businesses
            </Heading>

            <Text pt="2" fontSize="md">
              {data.controlledBusinesses?.map((b) => {
                return (
                  <Box mb={2}>
                    <Text>Code: {b.code}</Text>
                    <Text>Area: {b.area}</Text>
                    <Link href={b.sourceUrl} isExternal target="_blank" mb={4}>
                      Source: {b.sourceUrl}
                    </Link>
                  </Box>
                );
              })}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Risky businesses
            </Heading>

            <Text pt="2" fontSize="md">
              {data.riskyBusinesses?.map((b) => {
                return (
                  <Box mb={2}>
                    <Text>Code: {b.code}</Text>
                    <Text>Area: {b.area}</Text>
                    <Link href={b.sourceUrl} isExternal target="_blank" mb={4}>
                      Source: {b.sourceUrl}
                    </Link>
                  </Box>
                );
              })}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Prohibited businesses
            </Heading>

            <Text pt="2" fontSize="md">
              {data.prohibitedBusinesses?.map((b) => {
                return (
                  <Box mb={2}>
                    <Text>Code: {b.code}</Text>
                    <Text>Area: {b.area}</Text>
                    <Link href={b.sourceUrl} isExternal target="_blank" mb={4}>
                      Source: {b.sourceUrl}
                    </Link>
                  </Box>
                );
              })}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Social links
            </Heading>

            <Text pt="2" fontSize="md">
              <Text>
                facebook:{' '}
                {data.socialLinks?.facebook ? (
                  <Link
                    href={data.socialLinks.facebook}
                    isExternal
                    target="_blank"
                  >
                    {data.socialLinks.facebook}
                  </Link>
                ) : (
                  '-'
                )}
              </Text>
              <Text>
                instagram:{' '}
                {data.socialLinks?.instagram ? (
                  <Link
                    href={data.socialLinks.instagram}
                    isExternal
                    target="_blank"
                  >
                    {data.socialLinks.instagram}
                  </Link>
                ) : (
                  '-'
                )}
              </Text>
              <Text>
                linkedin:{' '}
                {data.socialLinks?.linkedin ? (
                  <Link
                    href={data.socialLinks.linkedin}
                    isExternal
                    target="_blank"
                  >
                    {data.socialLinks.linkedin}
                  </Link>
                ) : (
                  '-'
                )}
              </Text>
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Team count
            </Heading>

            <Text pt="2" fontSize="md">
              {data.teamCount || '-'}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Summary
            </Heading>

            <Text pt="2" fontSize="md">
              {data.summary || '-'}
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
}

export function GptNaicsForm() {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data } = await api.getGptNaics(params);
      return data;
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const naicsCodes = formData
      .get('NAICS')
      .split(',')
      .map((code) => code.trim());

    if (!formData.get('rootUrl')) {
      return alert('Please provide a URL');
    }

    try {
      new URL(formData.get('rootUrl'));
    } catch (e) {
      return alert('Invalid URL');
    }

    const params = {
      rootUrl: formData.get('rootUrl'),
      pageLimit: formData.get('pageLimit'),
      precision: formData.get('precision'),
      nocache: formData.has('nocache') ? '1' : '',
      NAICS: naicsCodes,
    };

    return mutation.mutate(params);
  }

  return (
    <Stack direction={'row'}>
      <Box minW={400}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} direction="column">
            <FormControl>
              <FormLabel htmlFor="rootUrl">
                The root URL for the website
              </FormLabel>
              <Input id="rootUrl" name="rootUrl" />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="NAICS">
                NAICS codes, comma separated (e.g. 1244,214124,124512)
              </FormLabel>
              <Input id="NAICS" name="NAICS" />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="pageLimit">Page limit</FormLabel>
              <Input id="pageLimit" name="pageLimit" defaultValue={5} />
            </FormControl>

            <RadioGroup name="precision" defaultValue="0">
              <FormLabel htmlFor="precision">Precision</FormLabel>
              <Stack direction="row" spacing={8}>
                <Radio value="0">0</Radio>
                <Radio value="1">1</Radio>
                <Radio value="2">2</Radio>
                <Radio value="3">3</Radio>
              </Stack>
            </RadioGroup>

            <Checkbox name="nocache">Ignore cache</Checkbox>
          </Stack>

          <Flex justify="flex-end">
            <Button
              mt={8}
              isLoading={mutation.isPending}
              type="submit"
              size="md"
              colorScheme="blue"
              disabled={mutation.isPending}
              minW={100}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
      <Box ml={8} mb={4} flex={1} maxWidth={'800px'}>
        {mutation.isPending ? (
          <Box p={2}>
            <Progress size="xs" isIndeterminate w="500px" />
          </Box>
        ) : (
          <>{mutation.data && <ResultCard data={mutation.data.response} />}</>
        )}
      </Box>
    </Stack>
  );
}
