import axios from 'axios';

const API_URL = 'https://kw1.gemba.dev/api';
// const API_URL = 'http://localhost:8787/api';

export const api = {
  runCheck: (data) => {
    return axios.post(`${API_URL}/media-check`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  fetchMediaCheck: (id) => {
    return axios.get(`${API_URL}/media-check/${id}`);
  },

  getGptNaics: (data) => {
    const queryParams = new URLSearchParams(data).toString();
    return axios.get(`${API_URL}/gpt-naics?${queryParams}`);
  },
};
