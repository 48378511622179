import * as React from 'react';
import {
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Link,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';

export function RunMediaCheckForm() {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      const { data } = await api.runCheck(formData);
      if (data.id) {
        navigate(`/view?id=${data.id}`);
      }
      return data;
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      searchQuery: formData.get('searchQuery'),
      type: formData.get('type'),
      pageLimit: formData.get('pageLimit'),
      precision: formData.get('precision'),
    };

    mutation.mutate(data);
  }

  return (
    <Stack direction={'row'}>
      <Box minW={400}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} direction="column">
            <FormControl>
              <FormLabel htmlFor="searchQuery">Search query</FormLabel>
              <Input id="searchQuery" name="searchQuery" />
            </FormControl>

            <RadioGroup name="type" defaultValue="company">
              <FormLabel htmlFor="type">Type</FormLabel>
              <Stack direction="column">
                <Radio value="company">Company</Radio>
                <Radio value="individual">Individual</Radio>
              </Stack>
            </RadioGroup>

            <FormControl>
              <FormLabel htmlFor="pageLimit">Page limit</FormLabel>
              <Input id="pageLimit" name="pageLimit" defaultValue={5} />
            </FormControl>

            <RadioGroup name="precision" defaultValue="0">
              <FormLabel htmlFor="precision">Precision</FormLabel>
              <Stack direction="row" spacing={8}>
                <Radio value="0">0</Radio>
                <Radio value="1">1</Radio>
                <Radio value="2">2</Radio>
                <Radio value="3">3</Radio>
              </Stack>
            </RadioGroup>
          </Stack>

          <Flex justify="flex-end">
            <Button mt={8} isLoading={mutation.isPending} type="submit" size="md" colorScheme="blue" minW={100}>
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
      <Box ml={8} w={500}>
        {mutation.data?.id && (
          <>
            <Heading size="md">Started media check with ID: </Heading>
            <Heading size="sm">
              <Button colorScheme="blue" variant="link" as={Link} href={`/view?id=${mutation.data.id}`}>
                {mutation.data.id}
              </Button>
            </Heading>
          </>
        )}
      </Box>
    </Stack>
  );
}
