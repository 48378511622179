import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Button, ChakraProvider } from '@chakra-ui/react';
import { Box, VStack, Text, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { RunMediaCheckForm } from './media-check/RunMediaCheckForm';
import { ViewMediaCheckForm } from './media-check/ViewMediaCheckForm';
import { GptNaicsForm } from './media-check/GptNaicsForm';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});
const isActive = (pathname, currentPath) => pathname === currentPath;

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box w="220px" h="100vh" bg="gray.100" p={4} position="fixed" top={0} left={0}>
      <VStack align="start" spacing={4}>
        <Text fontSize="xl">
          <Link to="/">
            <Button variant="link" color={isActive('/', currentPath) ? '#2066e6' : 'gray.600'}>
              Run media check
            </Button>
          </Link>
        </Text>
        <Text fontSize="xl">
          <Link to="/view">
            <Button variant="link" color={isActive('/view', currentPath) ? '#2066e6' : 'gray.600'}>
              View media check
            </Button>
            {/* <Button>View media check</Button> */}
          </Link>
        </Text>
        <Text fontSize="xl">
          <Link to="/gpt-naics">
            <Button variant="link" color={isActive('/gpt-naics', currentPath) ? '#2066e6' : 'gray.600'}>
              NAICS check
            </Button>
            {/* <Button>NAICS check</Button> */}
          </Link>
        </Text>
      </VStack>
    </Box>
  );
};

const MainContent = () => {
  return (
    <Box ml="250px" p={4} h="100vh" w="100%">
      <Routes>
        <Route index element={<RunMediaCheckForm />} />
        <Route path="/view" element={<ViewMediaCheckForm />} />
        <Route path="/gpt-naics" element={<GptNaicsForm />} />
      </Routes>
    </Box>
  );
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <Router>
          <Flex>
            <Sidebar />
            <MainContent />
          </Flex>
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  );
}
